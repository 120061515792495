.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
}

.dzu-dropzoneActive {
  background-color: #DEEBFF;
  border-color: #2484FF;
}

.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #2484FF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #E6E6E6;
  color: #2484FF;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-previewContainer {
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ECECEC;
  box-sizing: border-box;
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
}

.dzu-previewFileName {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1;
}

.dzu-submitButton {
  padding: 0 14px;
  min-height: 32px;
  background-color: #2484FF;
  border: none;
  border-radius: 4px;
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  background-color: #E6E6E6;
  color: #333333;
  cursor: unset;
}

.mde-header {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid #c8ccd0;
  border-radius: 2px 2px 0 0;
  background: #f9f9f9; }
  .mde-header .mde-tabs {
    display: flex;
    flex-direction: row; }
    .mde-header .mde-tabs button {
      border-radius: 2px;
      margin: 6px 3px;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer; }
      .mde-header .mde-tabs button:first-child {
        margin-left: 6px; }
      .mde-header .mde-tabs button.selected {
        border: 1px solid #c8ccd0; }
  .mde-header .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -.125em; }
  .mde-header button:focus {
    outline: 0; }
  .mde-header ul.mde-header-group {
    margin: 0;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-wrap: nowrap; }
    .mde-header ul.mde-header-group.hidden {
      visibility: hidden; }
    .mde-header ul.mde-header-group li.mde-header-item {
      display: inline-block;
      position: relative;
      margin: 0 4px; }
      .mde-header ul.mde-header-group li.mde-header-item button {
        text-align: left;
        cursor: pointer;
        height: 22px;
        padding: 4px;
        margin: 0;
        border: none;
        background: none;
        color: #242729; }

@-webkit-keyframes tooltip-appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes tooltip-appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::before {
          -webkit-animation-name: tooltip-appear;
                  animation-name: tooltip-appear;
          -webkit-animation-duration: 0.2s;
                  animation-duration: 0.2s;
          -webkit-animation-delay: 0.5s;
                  animation-delay: 0.5s;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;
          opacity: 0;
          position: absolute;
          z-index: 1000001;
          width: 0;
          height: 0;
          color: rgba(0, 0, 0, 0.8);
          pointer-events: none;
          content: "";
          border: 5px solid transparent;
          top: -5px;
          right: 50%;
          bottom: auto;
          margin-right: -5px;
          border-top-color: rgba(0, 0, 0, 0.8); }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::after {
          -webkit-animation-name: tooltip-appear;
                  animation-name: tooltip-appear;
          -webkit-animation-duration: 0.2s;
                  animation-duration: 0.2s;
          -webkit-animation-delay: 0.5s;
                  animation-delay: 0.5s;
          -webkit-animation-fill-mode: forwards;
                  animation-fill-mode: forwards;
          font-size: 11px;
          opacity: 0;
          position: absolute;
          z-index: 1000000;
          padding: 5px 8px;
          color: #fff;
          pointer-events: none;
          content: attr(aria-label);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 3px;
          right: 50%;
          bottom: 100%;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%);
          margin-bottom: 5px;
          white-space: nowrap; }
      .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown {
        position: absolute;
        left: 0;
        top: 30px;
        background-color: white;
        border: 1px solid #c8ccd0;
        padding: 5px;
        z-index: 2;
        -webkit-transform: translateX(-9px);
                transform: translateX(-9px); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li {
          margin: 0;
          white-space: nowrap;
          list-style: none;
          display: block; }
          .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button {
            display: block;
            height: auto; }
            .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p {
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              line-height: 1em;
              background: none;
              border: 0;
              text-align: left; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p:hover {
                color: #4078c0; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-1 {
                font-size: 20px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-2 {
                font-size: 18px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-3 {
                font-size: 14px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-4 {
                font-size: 12px; }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.15);
          top: -16px;
          left: 3px;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-bottom-color: white;
          top: -14px;
          left: 5px;
          -webkit-transform: translateX(50%);
                  transform: translateX(50%); }

textarea.mde-text {
  width: 100%;
  border: 0;
  padding: 10px;
  vertical-align: top;
  resize: none;
  overflow-y: auto; }

.mde-preview .mde-preview-content {
  padding: 10px; }
  .mde-preview .mde-preview-content p, .mde-preview .mde-preview-content blockquote, .mde-preview .mde-preview-content ul, .mde-preview .mde-preview-content ol, .mde-preview .mde-preview-content dl, .mde-preview .mde-preview-content table, .mde-preview .mde-preview-content pre {
    margin-top: 0;
    margin-bottom: 16px; }
  .mde-preview .mde-preview-content h1, .mde-preview .mde-preview-content h2, .mde-preview .mde-preview-content h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.3em; }
  .mde-preview .mde-preview-content h1 {
    font-size: 1.6em; }
  .mde-preview .mde-preview-content h2 {
    font-size: 1.4em; }
  .mde-preview .mde-preview-content h3 {
    font-size: 1.2em; }
  .mde-preview .mde-preview-content ul, .mde-preview .mde-preview-content ol {
    padding-left: 2em; }
  .mde-preview .mde-preview-content blockquote {
    margin-left: 0;
    padding: 0 1em;
    color: #777;
    border-left: 0.25em solid #ddd; }
    .mde-preview .mde-preview-content blockquote > :first-child {
      margin-top: 0; }
    .mde-preview .mde-preview-content blockquote > :last-child {
      margin-bottom: 0; }
  .mde-preview .mde-preview-content code {
    padding: 0.2em 0 0.2em 0;
    margin: 0;
    font-size: 90%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px; }
    .mde-preview .mde-preview-content code::before, .mde-preview .mde-preview-content code::after {
      letter-spacing: -0.2em;
      content: "\A0"; }
  .mde-preview .mde-preview-content pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border-radius: 3px; }
    .mde-preview .mde-preview-content pre code {
      display: inline;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0; }
      .mde-preview .mde-preview-content pre code::before, .mde-preview .mde-preview-content pre code::after {
        content: none; }
    .mde-preview .mde-preview-content pre > code {
      padding: 0;
      margin: 0;
      font-size: 100%;
      word-break: normal;
      white-space: pre;
      background: transparent;
      border: 0; }
  .mde-preview .mde-preview-content a {
    color: #4078c0;
    text-decoration: none; }
    .mde-preview .mde-preview-content a:hover {
      text-decoration: underline; }
  .mde-preview .mde-preview-content > *:first-child {
    margin-top: 0 !important; }
  .mde-preview .mde-preview-content > *:last-child {
    margin-bottom: 0 !important; }
  .mde-preview .mde-preview-content::after {
    display: table;
    clear: both;
    content: ""; }
  .mde-preview .mde-preview-content table {
    display: block;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse; }
    .mde-preview .mde-preview-content table thead th {
      font-weight: bold; }
    .mde-preview .mde-preview-content table th, .mde-preview .mde-preview-content table td {
      padding: 6px 13px;
      border: 1px solid #c8ccd0; }

* {
  box-sizing: border-box; }

.react-mde {
  border: 1px solid #c8ccd0;
  border-radius: 2px; }
  .react-mde .grip {
    border-top: 1px solid #c8ccd0;
    background-color: #f9f9f9;
    text-align: center;
    height: 10px;
    color: black;
    cursor: s-resize; }
    .react-mde .grip .icon {
      height: 10px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\D7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.uppload-bg {
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(150, 150, 150, 0.3); }

.uppload-modal {
  position: fixed;
  color: #333;
  background: #fff;
  z-index: 11000;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: 0 30px 150px rgba(0, 0, 0, 0.2); }
  .uppload-modal > div {
    position: static; }

.uppload-modal,
.uppload-bg {
  display: none;
  opacity: 0; }
  .uppload-modal.visible,
  .uppload-bg.visible {
    opacity: 1;
    display: block; }
  .uppload-modal.fadeIn,
  .uppload-bg.fadeIn {
    -webkit-animation: fadeIn 0.4s;
            animation: fadeIn 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .uppload-modal.fadeOut,
  .uppload-bg.fadeOut {
    -webkit-animation: fadeOut 0.4s;
            animation: fadeOut 0.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .uppload-modal.hidden,
  .uppload-bg.hidden {
    display: none; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.uppload-bg::after {
  z-index: 1;
  cursor: pointer;
  content: "\D7";
  font-family: sans-serif;
  position: fixed;
  font-size: 32px;
  line-height: 0.5;
  right: 2vw;
  top: 2vw;
  color: rgba(0, 0, 0, 0.5); }

@media (min-width: 860px) {
  .uppload-modal {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 720px;
    height: 500px; } }

@media (min-width: 960px) {
  .uppload-modal {
    width: 800px; } }

@media (max-height: 600px) {
  .uppload-modal {
    top: 10%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    height: auto;
    bottom: 10%; } }

@media (max-width: 860px) {
  .uppload-modal {
    left: 0;
    right: 0;
    top: 10vh;
    bottom: 0; }
    .uppload-modal > div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column; }
    .uppload-modal section {
      flex: 1 0; } }

.uppload-modal aside {
  background-color: #dfe6e9;
  -webkit-transition: 1s;
  transition: 1s; }
  .uppload-modal aside.hidden {
    opacity: 0.333; }
  .uppload-modal aside nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .uppload-modal aside nav ul li svg {
      margin-right: 0.5rem;
      opacity: 0.5;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
    .uppload-modal aside nav ul li button {
      text-align: left;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      font: inherit;
      background: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: none;
      padding: 1rem;
      border-radius: 0;
      color: inherit; }
      .uppload-modal aside nav ul li button:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.075); }
        .uppload-modal aside nav ul li button:focus svg {
          opacity: 1; }
    .uppload-modal aside nav ul li.active button {
      background-color: #fff; }
      .uppload-modal aside nav ul li.active button svg {
        opacity: 1; }

.uppload-modal section {
  display: flex; }

@media (min-width: 860px) {
  .uppload-modal aside {
    width: 200px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding-bottom: 2rem; }
    .uppload-modal aside nav li button {
      display: block;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.075); }
      .uppload-modal aside nav li button:hover {
        background-color: rgba(0, 0, 0, 0.05); }
  .uppload-modal section {
    position: absolute;
    right: 0;
    left: 200px;
    top: 0;
    bottom: 0;
    overflow-y: auto; } }

.uppload-branding {
  font-size: 80%;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  position: fixed;
  bottom: 1rem;
  left: 1rem; }
  .uppload-branding:hover {
    color: rgba(0, 0, 0, 0.5); }

@media (max-width: 860px) {
  .uppload-modal aside nav ul {
    white-space: nowrap;
    overflow-x: auto; }
  .uppload-modal aside nav li {
    display: inline-block; } }

.uppload-modal .primary-button.instagram {
  background-color: #b53471; }
  .uppload-modal .primary-button.instagram:hover {
    background-color: #833471; }

.uppload-modal .primary-button.facebook {
  background-color: #3b5998; }
  .uppload-modal .primary-button.facebook:hover {
    background-color: #2f4779; }

.uppload-modal .crop-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .uppload-modal .crop-container #imageCropper {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.25);
    width: 300px;
    height: 200px;
    left: 100px;
    top: 100px;
    cursor: move; }
  .uppload-modal .crop-container .preview {
    flex: 1 0;
    overflow: auto; }
    .uppload-modal .crop-container .preview > div > img {
      margin: auto; }
    .uppload-modal .crop-container .preview .croppr-container {
      text-align: center;
      margin: auto; }
  .uppload-modal .crop-container .bottom-buttons {
    text-align: center; }
    .uppload-modal .crop-container .bottom-buttons .toolbar {
      background: #2c3e50; }
      .uppload-modal .crop-container .bottom-buttons .toolbar button {
        background: none;
        color: #fff;
        border-radius: none;
        padding: 1rem; }
    .uppload-modal .crop-container .bottom-buttons .cta {
      padding: 0.5rem 0; }
    .uppload-modal .crop-container .bottom-buttons #aspectButtons button {
      margin: 0 0.25rem; }

.uppload-modal img {
  max-width: 100%; }

.uppload-modal #previewImage {
  display: none;
  max-height: 500px; }

.croppr-container * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box; }

.croppr {
  position: relative;
  display: inline-block; }

.croppr-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: crosshair; }

.croppr-region {
  border: 1px dashed rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 3;
  cursor: move;
  top: 0; }

.croppr-imageClipped {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.croppr-handle {
  border: 1px solid black;
  background-color: white;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 4;
  top: 0; }

.preview-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .preview-container .preview {
    flex: 1 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .preview-container .preview img {
      margin: 0 auto; }
  .preview-container .bottom-buttons {
    padding: 0.5rem;
    text-align: center; }

.camera-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .camera-container .preview {
    flex: 1 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .camera-container .preview video {
      margin: 0 auto; }
  .camera-container .bottom-buttons {
    padding: 0.5rem;
    text-align: center; }
  .camera-container #cameraCanvas,
  .camera-container #cameraError {
    display: none; }
  .camera-container #cameraError,
  .camera-container #cameraPermission {
    text-align: center;
    font-size: 125%; }
    .camera-container #cameraError svg,
    .camera-container #cameraPermission svg {
      margin-bottom: 1rem; }
      .camera-container #cameraError svg path,
      .camera-container #cameraPermission svg path {
        fill: #aaa; }

.uppload-modal #dragDropElement {
  border: 3px dashed #ddd;
  padding: 5rem 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 125%; }
  .uppload-modal #dragDropElement.active {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background-color: whitesmoke; }

.uppload-modal #dragDropFileElt {
  display: none !important; }

.uppload-modal .currentPage {
  display: flex;
  flex: 1 0; }

.uppload-modal .center-middle {
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 5rem;
  right: 5rem; }

.uppload-modal input {
  border: 2px solid #eee;
  width: 350px;
  max-width: 85vw;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  display: block; }
  .uppload-modal input:focus {
    outline: none;
    border-color: #bbb; }

.uppload-modal .primary-button {
  background-color: #00a8ff;
  color: #fff;
  border: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem; }
  .uppload-modal .primary-button:hover {
    background-color: #0097e6; }
  .uppload-modal .primary-button.secondary {
    background-color: #95a5a6; }
    .uppload-modal .primary-button.secondary:hover {
      background-color: #7f8c8d; }

.uppload-modal .uppload-modal p {
  margin-top: 0;
  margin-bottom: 1rem; }
  .uppload-modal .uppload-modal p:last-child {
    margin-bottom: 0; }

.uppload-modal .mb-full {
  margin-bottom: 1.5rem !important; }

.uppload-modal .loading-spinner,
.uppload-modal .uploaded-icon {
  color: #aaa;
  font-size: 300%;
  margin-bottom: 1.5rem; }

.uppload-modal .uploaded-icon {
  background-color: #2ed573;
  color: #fff;
  padding: 1rem;
  border-radius: 100%; }

.uppload-modal .errorMessage {
  display: none;
  color: rgba(255, 255, 255, 0.9);
  background-color: #ff4757;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10; }
  .uppload-modal .errorMessage.visible {
    opacity: 1; }

.uppload-modal label {
  font-size: 110%;
  margin-bottom: 1.5rem; }
  .uppload-modal label div {
    margin-bottom: 1rem; }

@media (max-width: 860px) {
  .uppload-modal .errorMessage {
    top: auto;
    bottom: 0; }
  .uppload-modal .center-middle {
    left: 1.5rem;
    right: 1.5rem; } }

